import React, {Component} from 'react';
import data from './data.json'

import './App.scss';

class App extends Component {
  state = {
    scrollTop: 0
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    let scrollTop = event.srcElement.documentElement.scrollTop

    this.setState({scrollTop})
  }

  render() {
    const {fullname, jobTitle, sections, smallSections, summary} = data
    const {scrollTop} = this.state

    let visibilityPercents = (150 - scrollTop) / 150

    if (visibilityPercents < 0) {
      visibilityPercents = 0
    }

    const profilePictureSize = 120 * visibilityPercents

    const profilePictureStyle = {transform: `scale(${visibilityPercents})`}
    const shouldShowProfilePicture = scrollTop < 150

    return (
      <div className="App">
        <div className={`main-details-container ${!shouldShowProfilePicture && 'fixed'}`}>
          {shouldShowProfilePicture && <div className="profile-picture" style={profilePictureStyle}></div>}
          <div className="name-container">
            <span>{fullname}</span>
          </div>
          <div className="job-title-container">
            <span>{jobTitle}</span>
          </div>

          <div className="summary" style={{opacity: visibilityPercents, height: 150 * visibilityPercents}}>
            {summary}
          </div>
        </div>

        <div className={`sections-container ${!shouldShowProfilePicture && 'with-margin-top'}`}>
          {Object.keys(sections).map((sectionKey) => {
            const {title, stages} = sections[sectionKey]

            return (
              <div className="section">
                <div className="title">{title}</div>

                <div className="stages-container">
                  {stages.map(({startTime, endTime, jobTitle: stageJobTitle, subTitle, logo, description}) => {
                    const descriptionParts = description.split('\n')

                    return (
                      <div className="stage">
                        <div className="point-in-time"></div>
                        <div className="time-container">
                          <div className="end-time">{endTime}</div>
                          <div className="start-time">{startTime}</div>
                          {logo && <img src={logo} className="logo" />}
                        </div>
                        <div className="details">
                          <div className="job-title">{stageJobTitle}</div>
                          <div className="sub-title">{subTitle}</div>
                          <ul className={`description ${descriptionParts.length > 1 && "list"}`}>{descriptionParts.map(text => <li>{text}</li>)}</ul>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}

          {Object.keys(smallSections).map(sectionKey => {
            const {title, stages} = smallSections[sectionKey]

            return (
              <div className="small-section">
                <div className="title">{title}</div>
                <ul className="stages-container">
                  {stages.map(stage => (
                    <li className="stage">
                      <div className="stage-title">{stage.title}</div>
                      <div className="description">{stage.description}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default App;
